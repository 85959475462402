import styled from 'styled-components';

export const PageContainer = styled.div `
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #0c0c0c;
    width: 100%;
    margin: auto;
    margin-top: -40px;
    margin-bottom: 0px;
    padding: 10px 0px;
    height: 100%;
    /* border: 2px solid green; */

 @media screen and (max-height: 1366px){
    padding: 390px 0px;

 }

 @media screen and (max-height: 880px){
    padding: 130px 0px;

 }

`;

export const HeroBg = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border: 2px solid green; */
    margin-bottom: 0px;

`; 

export const VideoBg =styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232834;
    z-index: 10;
`;


export const HeroWrapper = styled.div`
    background: transparent;
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 40px 0px;
    width: 80%;
    height: auto;
    z-index:10;
    border-radius: 10px;
`;

export const HeroHeaderWrapper = styled.div `
   display: flex;
   justify-content: start;
   align-items: center;
   background: transparent;
   flex-direction: row;
   width: auto;
   margin-top: 0px;
   padding-top: 0px;
   z-index: 10;
   /* border: solid 2px blue; */
   
  @media screen and (max-width: 768px){
        width:80%;
    }

  @media screen and (max-width: 720px){
        flex-direction: column;
        height:auto;
    }

`;

export const HeroH1 = styled.h1 `
    color: #FF8C00;
    font-size: 60px;
    padding:0px;
    margin-left: 0px;

 @media screen and (max-width: 768px){
     font-size:40px;
 }

 @media screen and (max-width: 480px){
     text-align: center;
     margin-left:0px;
 }

 @media screen and (max-width: 280px){
     text-align: center;
     font-size: 32px;
     margin-left:0px;
 }


`;

export const HeroH3 = styled.h3 `
    color: #FF8C00;
    font-size: 25px;
    margin-left: 20px;
    text-align: left;
   
  @media screen and (max-width: 768px){

        font-size: 1rem;
        margin-top: 0px;
    }

  @media screen and (max-width: 500px){
        margin-top: 0px;
        margin-left:0px;
        text-align: center;
    }
`;


export const HeroLogo = styled.img `
   width: 150px;
   border-radius: 10px;
   height: 100%;
   padding: 0px;
   margin-right: 10px;

 @media screen and (max-width: 768px){
        font-size: 1rem;
        margin-top: 0px;
    }

  @media screen and (max-width: 468px){
        height: 200px;
        width: 200px;
        margin: 0px;
    }

  @media screen and (max-width: 280px){
        height: 100px;
        width: 100px;
        margin: 0px;
    }

`;

export const HeroTitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;

`;

export const HeroMainTitle = styled.div `
  color: white;
  /* width: 100%; */
  margin: auto;
  padding: 0px;

  @media screen and (max-width: 768px){
        font-size: 2rem;
        margin-top:0px;
    }

  @media screen and (max-width: 500px){
        font-size: 2rem;
        margin-top: 5px;
    }
  
  @media screen and (max-width: 280px){
        font-size: 1rem;
        margin-top: 5px;
    }  
`;

export const HeroSubTitle = styled.div `
  
  color: white;
  margin-top: 0px;

`;

export const Section1 = styled.div `
   display: flex;
   justify-content: center;
   align-items: center;
   background: transparent;
   box-shadow: 5px 5px #FF7F50; 
   border-bottom-color: #FF7F50;
   border-right-color: #FF7F50;
   border-bottom-style: outset;
   border-right-style: outset;
   width: 50%;
   height: 100px;


   
   @media screen and (max-width: 768px) {
    width: 90%;
    height: 80px;
    padding: px;
    margin: px;
   }

   @media screen and (max-width: 500px) {
    width: 90%;
    height: 80px;
    padding: px;
    margin-top: 0px;
    margin-bottom: 0px;
   }

`;
export const SectH1 = styled.h1 `
    color: #D2691E;
    font-size: 32px;
    padding:0px;
    text-align: center;
    padding: 5px;
    text-shadow: 2px 2px 4px #FF7F50;
    transition: 2s ease-in;

 @media screen and (max-width: 1024px){
    text-align: center;
     padding: 5px;
     font-size:18px;
 }
 
 
 @media screen and (max-width: 768px){
    text-align: center;
     padding: 5px;
     font-size:18px;
 }

 @media screen and (max-width: 480px){
     font-size: 18px;
     text-align: center;
     padding: 5px;
     margin-left:2px;
 }

@media screen and (max-width: 280px){
        font-size: 16px;
        margin-top: 5px;
    }  

 @keyframes animateH1 {
     from {left: -50px;}
     to {left: 50px;}
 }
`;

