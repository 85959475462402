import React from 'react'
import HeroComponent from '../components/HeroComponent';

const Home = () => {
    return (
        <>
          <HeroComponent />
        </>
    )
}

export default Home
