import React, {useState, useEffect} from 'react';
import EachKeyDate from './EachKeyDate';
import './style.css';



const KeyDates = () => {
 const[data, setData] = useState([]);
 const url = 'https://anai-singapore.org/Backend/api/jsondates/read.php';
 
// const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
// let currMth = month[new Date().getMonth()];

  // console.log(currMth);

const getData = () => {
           fetch(url)
              .then((response) => response.json())
              .then((resdata) => {
                setData(resdata);
              })
              .catch((error) => {
                console.error(
                  "There has been a problem with your fetch operation:",
                  JSON.parse(JSON.stringify(error))
                );
              });
   }

//Logic check for the items that within current mth. 

useEffect(() => {
  getData();
}, []);  
//Logic check for the items that within current mth. 

  return (
        <>
        
        {data.map((item, idx)=> {
          
          
          return <EachKeyDate key={item.id} {...item} />;
        })}
        </>
      );
};

export default KeyDates;
