import React from 'react'
import Img from '../../Images/GuruRaghavendra.png'
import {
        HeroHeaderWrapper,
        HeroLogo,
        HeroTitleWrapper,
        HeroMainTitle,
        HeroSubTitle,
        HeroH1, HeroH3,
    } from '../HeroComponent/HeroElements';


const Pageheader = () => {
    return (
        <>
             <HeroHeaderWrapper>
                <HeroLogo src={Img} alt="Guru Sharanam" />
                <HeroTitleWrapper>
                 <HeroMainTitle>
                   <HeroH1>ANAI</HeroH1>
                 </HeroMainTitle>
                 <HeroSubTitle>
                  <HeroH3>Aathma Nyaana Anbu Illam</HeroH3>
                 </HeroSubTitle>
                </HeroTitleWrapper>
             </HeroHeaderWrapper>
        </>
    )
}

export default Pageheader;
