import React from 'react'
import Video from '../videos/galleryvideo.mp4'
import PageHeader from '../components/PageHeader';
import ImageGallery from '../components/ImageGallery';
import {
        GalleryHeaderWrapper,
        GalleryHero,
        HeroBg,
        VideoBg,
        HeroVideoOverlay,
        HeroLabel} from '../components/PageElements.js'


const Gallery = () => {
    return (
        <>
        <GalleryHeaderWrapper>
            <PageHeader />
        </GalleryHeaderWrapper>
        <GalleryHero>
           <HeroBg>
             <VideoBg
                autoPlay 
                muted 
                loop
                playsInline 
                src={Video}
                type="video/mp4"
             />
             <HeroVideoOverlay />
            </HeroBg>
            <HeroLabel>
             Our Gallery
           </HeroLabel>
           
         </GalleryHero>
         <ImageGallery />

        </>
    )
}

export default Gallery
