import React, {useEffect} from 'react'
import Video from '../videos/jothi.mp4'
import PageHeader from '../components/PageHeader';
import {GalleryHeaderWrapper,
        GalleryHero,
        HeroBg,
        VideoBg,
        HeroLabel} from '../components/PageElements.js/index.js';
// import About from '../components/OurStory'


const OurStory = () => {

useEffect(()=> {
   window.scrollTo(0,0);
},[])

    return (
        <>
         <GalleryHeaderWrapper>
           <PageHeader />
         </GalleryHeaderWrapper>
         <GalleryHero>
           <HeroBg>
             <VideoBg
                autoPlay 
                muted 
                loop
                playsInline 
                src={Video}
                type="video/mp4"
             />
             {/* <HeroVideoOverlay /> */}
            </HeroBg>
            <HeroLabel>
            Frequently Asked Questions
           </HeroLabel>
         </GalleryHero>
         

     

        </>
    )
}

export default OurStory;