import React, {useEffect} from 'react'
import Video from '../videos/keydates.mp4'
import PageHeader from '../components/PageHeader';
import {GalleryHeaderWrapper,
        GalleryHero,
        HeroBg,
        VideoBg,
        HeroLabel,
        HeroVideoOverlay} from '../components/PageElements.js/index.js';
import RenderDates from '../components/RenderDates';


const OurStory = () => {
  let currYear = new Date().getFullYear();

useEffect(()=> {
   window.scrollTo(0,0);
},[])

    return (
        <>
         <GalleryHeaderWrapper>
           <PageHeader />
         </GalleryHeaderWrapper>
         <GalleryHero>
           <HeroBg>
             <VideoBg
                autoPlay 
                muted 
                loop
                playsInline 
                src={Video}
                type="video/mp4"
             />
             <HeroVideoOverlay />
            </HeroBg>
            <HeroLabel>
            Key Dates for {currYear}
           </HeroLabel>
         </GalleryHero>
          <RenderDates />
        </>
    )
}

export default OurStory;