
import React, {useState} from 'react';
import './App.css';
// import Main from './components/HeroComponent';
import Sidebar from './components/SidebarMenu/Sidebar';
import Home from './pages/Home';
import OurStory from './pages/OurStory';
import Gallery from './pages/Gallery';
import GetInTouch from './pages/GetInTouch';
import Footer from './components/Footer';
import KeyDates from './pages/KeyDates';
import FAQ from './pages/FAQ';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {
  const [isOpen, setIsOpen]=useState(false)

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <Router>
     <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Switch>
       <Route path='/' exact component={Home} />
       <Route path='/OurStory' exact component={OurStory} />
       <Route path='/Gallery' exact component={Gallery} />
       <Route path='/GetInTouch' exact component={GetInTouch} />    
       <Route path='/KeyDates' exact component={KeyDates} /> 
       <Route path='/FAQ' exact component={FAQ} /> 
      </Switch>
      <Footer />
    </Router>

     
  );
}

export default App;
