import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: #f0ebe1;
  position: relative;
  width:100%;
  height: auto;

  
`;

export const FooterText = styled.h2 `
  font-size: 0.6rem;
  text-align: right;
  color: #3f2d0c;
  padding-right: 20px;
`;

export const FooterTextBox = styled.div `
 display:flex;
 justify-content: center;
 align-items: center;
  width: auto;
  height: 50px;
  /* justify-content: right; */

`;
