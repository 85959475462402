import styled from 'styled-components';

export const SliderWrapper = styled.div`
   display: flex;
   justify-content: center;
   /* align-items: center; */
   flex-direction: row;
   /* align:center; */
   /* height: auto; */
   width: auto;
   padding: 20px;
   background: linear-gradient(36deg, rgba(209,124,38,1) 0%, rgba(223,168,28,1) 5%, rgba(223,168,28,0.4990371148459384) 57%, rgba(223,168,28,0.20772058823529416) 88%);

@media screen and (max-width: 1024px){
    /* flex-direction: column; */
    width: auto;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

`

export const SliderImagesWrapper = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   width: 80%;
   
   /* border: solid 2px blue; */



@media screen and (max-width: 1024px){
    order: 2;
     width: 100%;
}

`

export const SliderSelectorWrapper = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: start;
   width:20%;
   height: auto;
   padding: 0px;
   box-shadow: 8px 5px 5px rgba(0,0,0,0.3);
   /* border: solid 2px red; */

@media screen and (max-width: 1024px){
    justify-content: center;
    font-size: 20px;
    order: 1;
    height: auto;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
}

`;

export const SelectorPanel = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items:start;
   width: 100%;
   height: 100%;
   margin: 10px;
   text-decoration: none;
   order: 2;
   /* border: dashed 2px purple; */

@media screen and (max-width: 1024px){
    justify-content: center;
    font-size: 20px;
    order: 1;
    height: auto;
    width: 100%;
}

@media screen and (max-width: 360px){
    justify-content: center;
    font-size: 16px;
    order: 1;
    height: auto;
    width: 100%;
  
    /* border: dashed 2px green; */

}
`