import React,{useState} from 'react';
import {
        SliderWrapper,
        SliderImagesWrapper,
        SliderSelectorWrapper,
        SelectorPanel
        } from './ImageGalleryElements';

import ImageSlider from '../ImageSlider';
import Getitems from '../Getitems';

const Gallery = () => {
 const [eventType, setEventType] = useState({eventId: 0, eventName:""});



    return (
        <>
          <SliderWrapper>
           <SliderImagesWrapper>
            <ImageSlider {...eventType}/> 
           </SliderImagesWrapper>
           <SliderSelectorWrapper>
            <SelectorPanel>
              <Getitems setEventType={setEventType}/>
            </SelectorPanel>
           </SliderSelectorWrapper>
         </SliderWrapper>
        </>
    )
};

export default Gallery;
