import React, {useState, useEffect} from 'react'
import InitialSlider from '../InitialSlider';
import {initialImgData} from './initialImgData';
// import './imageSlider.css';

const ImageSlider = ({eventId, eventName}) => {

// const url = `http://localhost/Backend/api/images/read_by_event.php?id=${eventId}`;
const url = `https://anai-singapore.org/Backend/api/images/read_by_event.php?id=${eventId}`;

const [imageData, setImageData] = useState([]);
const [initial, setInitial] = useState(false);
const [isLoading, setIsLoading] = useState(true);


useEffect(() => {

  if(eventId > 0){
  
    fetch(url)
    .then(response => response.json())
    .then((resdata) => {
        // console.log(resdata);
        setImageData(resdata);
 
         })
    .catch(error => {
      console.error(error);
  });
   setIsLoading(false);

  } else {

    setInitial(true);
    setImageData(initialImgData);
    setIsLoading(false);

  }
  
  return () => {
    setInitial(false);
  }

},[eventId]);

if(isLoading){
  return (
    <h3>Loading...</h3>
  )
}

if(initial){
  return (
    <>
       <InitialSlider imageData={imageData} eventName={eventName}/>
    </>
  )
}

  return (
      //  <>
      //   <Slider imageData={imageData} eventName={eventName} />
      //  </>
       <>
        <InitialSlider imageData={imageData} eventName={eventName} />
       </>
    
    )
}

export default ImageSlider;
