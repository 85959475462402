import React, {useState, useRef} from 'react';
import DisplayByMth from './DisplayByMth';
import Img from '../../Images/guru_location.png';
import './styles.css';
import { BsFillArrowDownCircleFill as FillAD} from "react-icons/bs";
//import DisplayByMth from './Components/DisplayByMth';

const Accordion = () => {
    const[isOpenPanel1, setIsOpenPanel1] = useState(false);
    const[isOpenPanel2, setIsOpenPanel2] = useState(false);
    const[isOpenPanel3, setIsOpenPanel3] = useState(false);
    const[isOpenPanel4, setIsOpenPanel4] = useState(false);

    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    let currMth = month[new Date().getMonth()];
    let currMthNum = new Date().getMonth();
    let currYear = new Date().getFullYear()


    const panel1 = useRef();
    const panel2 = useRef();
    const panel3 = useRef();
    const panel4 = useRef();
    
  console.log(currMthNum)

    const toggle = (panelid) => {

           if(panelid === panel1){
              setIsOpenPanel1(!isOpenPanel1)
           }else if(panelid === panel2){
               setIsOpenPanel2(!isOpenPanel2)
           }else if(panelid === panel3){
             setIsOpenPanel3(!isOpenPanel3)
           }else
                setIsOpenPanel4(!isOpenPanel4)
    }

  return (
    <>
      <div className="accordion" id="stayOpen"> {/*accordion container*/}
        

        {/*Prayer and Pooja times */}
        <div className="accordion-panel" id="panel1" ref={panel1}>
          <div className="accordion-header" onClick={() => toggle(panel1)}>
            <p> Prayer and Poojai times</p>
            <span><FillAD className={isOpenPanel1 ? "icon open" : "icon"} /></span>
          </div>
          <div id="collapse1" className={isOpenPanel1 ? "accordion-collapse show" : "accordion-collapse" }>
            <div className="accordion-body">
              Regular Sri Guru Raghavendra Swamy arathanas are conducted on a weekly basis at our Ashram on Saturdays starting at 6:00pm, accept for 
              for those Ekadashi fasting days that falls on a Saturday. <br /><br />Please note important Dates for current month below.
            </div>
          </div>
        </div>
        {/*Our Location */}
        <div className="accordion-panel" id="panel2" ref={panel2}>
          <div className="accordion-header" onClick={() => toggle(panel2)}>
            <p> Our Location</p>
            <span><FillAD className={isOpenPanel2 ? "icon open" : "icon"} /></span>
          </div>
          <div id="collapse2" className={isOpenPanel2 ? "accordion-collapse show" : "accordion-collapse"}>
            <div className="accordion-body">
              We are located at 43 Jalan Lengkok, Sembawang, Singapore (759225)<br/><br/>
              <img className="accordionimg" src={Img} alt="Guru Location" />
            </div>
          </div>
        </div>
                {/*Key Dates */}
        <div className="accordion-panel" id="panel3" ref={panel3}>
          <div className="accordion-header" onClick={() => toggle(panel3)}>
            <p> Important Dates for {currMth} {currYear}</p>
             <span><FillAD className={isOpenPanel3 ? "icon open" : "icon"} /></span>
          </div>
          <div id="collapse3" className={isOpenPanel3 ? "accordion-collapse show" : "accordion-collapse"}>
            <div className="accordion-body">
              <DisplayByMth currMth={currMth} currMthNum={currMthNum}/> 
               <p>Please see all Key Dates for the calendar year in the menu</p>
            </div>
          </div>
        </div>
      </div>{/*accordion container*/}
    </>
  );
};

export default Accordion;
