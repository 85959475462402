import React, { useState, useRef } from "react";
import { Container } from "./OurStoryElements";
import Timeline from "../Timeline";

const OurStory = () => {
  const [showMore, setShowMore] = useState(false);

  const paraRef = useRef();

  // if(paraRef.current) console.log(paraRef.current.scrollHeight);

  const toggleDisplay = () => {
    setShowMore(!showMore);

  };

  return (
    <Container>
      <div className="timeline">
        <Timeline />
      </div>
      <div className="box">
        <h3>Our begining</h3>
        <div className="ourstory">
          It all started with "Nee Saiyi" - <b>You do it</b>, words by our Guru,
          Sri Raghavendra Swamy, where it all started.
          <br />
          <br />
          After the resounding instruction from Guru, it reverberated a thought
          process that made Parathy, Panirselvam, Gunasegaren and Devagunalan to
          come together as a team. They analysed the social implications on our
          youth, the lack of knowledge and the importance of spirituality in our
          daily life. Hence they started to put together a development programme
          that will benefit our younger generation and their parents. This group
          became the founding members of this movement.
          <br />
          <br />
          They focused on their own children at first. Weekly bajan sessions,
          proper adherence to spirituality and prayer practices was imparted to
          this children. Each person within the group conducted the sessions on
          a weekly basis at each others’ residences.
          <br />
          <br />
          Through word of mouth, many members of the community within Woodlands
          (suburb in Singapore) showed interest to send their children to join
          these sessions too. It was turning into a community event. Within a
          few months, the number of children increased to almost 70. This
          prompted the need to seek a central location became imperative to
          facilitate these sessions. Hiring community halls for this purpose was
          proving to be a financial strain. Parathy (forefather of the founding
          members) approached the then President, Mr. Sivalingam, of the Siva
          Krishnan Temple, in Marsiling. He whole heartedly allowed us to use
          the multi-purpose hall on Saturdays between 3.30pm and 6.30pm. The
          class curriculum expanded to include bhajans, meditation and to
          educate young people of life values, as a programme for youth
          development. As part of this process, outings were also organised for
          the members to visit orphanages, temples and old folks homes to expand
          their knowledge and the community better.The generosity of the temple
          committee, allowed the continuation of our voluntary services to the
          public and conduct annual Guru Arathanas.
          <br />
          <div className={showMore ? "showMorePara" : "showMorePara show"}
                      ref={paraRef} 
                  style={showMore ? 
                          {
                            height: paraRef.current.scrollHeight + "px"
                          }:
                          {height: "0px"}
                  }
          >
             <p> 
                  
                  <br />
                  The impetus to officially form an organisation was incepted during Sat
          Guru Sri Raghavendra Swamy Arathana in 2003. After the successful
          event, the group got together to discuss the core aims, vision,
          mission statements of this organisation. We brainstormed for an
          appropriate name for our organisation. With Guru's blessings, Athma
          Nyaana Anbu Illam, was formed on the 14th of March 2004. The
          abbreviation, <b>ANAI</b> translates to welcome or hug in tamil. To
          the likeness of open arms of a welcoming mother.
          <br />
          <br />
                  After more than 2 years at the temple, the sessions had to be
                  relocated as the temple was to undergo refurbishment work. Hence
                  the search started again for a new location. Then after a couple
                  of months, with Guru's blessings, Mr. Ravi and Mrs. Viji, offered
                  us to continue our sevas at their Vits Education Centre (VEC) at
                  Marsiling. ANAI continued its services for a further 3 years at
                  this location. Then in 2009, we had to move as VEC was sold.
                  Nevertheless, we were allowed to continue at another of their
                  location, within the same vicinity.
                  <br />
                  <br />
                  By this time the need for a bigger premise was becoming important
                  to accommodate all the children and we began segregating them into
                  different groups. Hence the search started again.
                  <br />
                  On the 3rd October 2010, it was a dream come true moment as an
                  opportunity arised and the decision was made to move to 43 Jalan
                  Lengkok Sembawang as our permanent location.
                  <br />
                  <br />
                  <b>Aathma Nyaana Anbu Illam (ANAI)</b> was officially opened on
                  the 5th December 2010.
                  <br />
                  <br />
                  On the 9th of October 2011, Sri Raghavendra Swamy statue was
                  delivered from India, sponsored by Mr & Mrs. Lal Bathathur, and
                  Mr. Murthy and Mrs. Tamilarasi. Since then regular abishegams and
                  poojas for our Guru Raghavendra Swamy has been conducted on a
                  weekly basis. Our Sevagas (volunteers / members) have been
                  actively involved in regular bhajans, a variety of educational,
                  spiritual and social-based activities. With Guru’s blessings, the
                  number of Sevagas has been steadily increasing. This enabled the
                  organisation of more spiritual activities, events and programmes
                  for all devotess. Our journey continues towards our aims, vision
                  and mission to love, care and share our Guru’s teachings to one
                  and all. We sincerely look forward to you joining us and
                  suppporting us in this journey.</p>
          </div>
          
                
          <button className="showMore" onClick={toggleDisplay}>
            {showMore ? "......Show less......" : "......Read more......"}
          </button>
        </div>
      </div>
      <div className="about-us">
        <div className="about-us-header">
          <h2>Aim</h2>
          <div className="aboutus-body">
            Create a centre (Ashram) for all, especially the youths, to realise
            the need of spirituality through meditation, a place to seek solace,
            understand the community, and the opportunity embrace the grace of
            Guru Raghavendra Swamy’s teachings.
          </div>
        </div>
        <div>
          <h2>Mission</h2>
           <div className="aboutus-body">
             <ul>
              <li>
                Guide our youths and the wider community towards righteousness.
              </li>
              <li>Inculcate good values from the teachings of our Guru</li>
              <li>Encourage the need to support one another</li>
              <li>Respect one another, our elders and all parents</li>
              <li>Experience spirituality through meditation and prayers</li>
            </ul>
           </div>
        </div>
        <div>
          <h2>Vision</h2>
          <div className="aboutus-body">
            With the grace and blessings of our Guru, we strive towards an
            established centre of excellence to enable individuals to understand
            oneself, provide service to the community and embrace spirituality
            as part of their life to bring peace within.
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OurStory;
