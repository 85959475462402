import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin: auto;
    height: 100%;
    width: 100%;
    padding-top: 20px;
    background-color: beige;
    scroll-behavior: smooth;

 
    .timeline {
       /* border: solid 2px green; */
       /* height:auto; */
       width: 20vw;
       padding-right:10px;
       order: 1;

    }
    .box{
       width: 60vw;
       padding-bottom: 30px;
       margin-bottom: 20px;
       height: auto;
       order: 2;
       margin-top: 0px;
       box-shadow: 5px 10px 10px rgba(0,0,0,0.2);
         
     h3{
        padding: 10px;
        text-align: center;
        font-size: 40px;
        color: brown;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    }

    .ourstory {
        font-size: 16px;
        padding: 0 12px;
        color: rgb(184, 134, 11);
        text-align: justify;
        letter-spacing: 2px;
        line-height: 1.5;
        overflow: hidden;

     }
     img.firstImg{
         width: 400px;
         height: auto;
         margin-left: 20px;
         padding: 10px;
         float: right;
     }
    img.secondImg{
      width: 200px;
      height: auto;
      margin-right: 20px;
      padding: 10px 10px 0px 10px;
      float: left;
      }

     .showMore{
        border: none;
        color: rgb(184, 134, 11); 
        background: none;
        position: relative;
        width: 100%;
        letter-spacing: 10px;
        cursor: pointer;
     }

     .showMorePara {
       height: 0px;
     }
      
     .showMorePara.show, .showMorePara {
       transition: height 2s ease-in-out;
       }
     }
}

    .about-us {
      position: sticky;
      top: 80px;
      order: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 20vw;
      margin-right: 30px;
      padding: 10px;
      height: auto;
      margin-top: 40px;
      box-shadow: 5px 10px 10px rgba(0,0,0,0.2);

      h3{
        padding: 10px;
        text-align: center;
        font-size: 30px;
        color: brown;
        text-align: left;
      }

      h2{
        padding: 10px;
        text-align: center;
        font-size: 20px;
        color: brown;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.2);
     }
      .aboutus-body{
        padding: 10px;;
        font-size: 12px;
        color: rgb(184, 134, 11);

      }

      li{
        margin-left: 20px;
      }
    }

    .MoreLess {
      margin-left:10px;
      font-size: 10px;
      font-weight: bold;   
    }


 @media screen and (max-width: 880px){
   flex-direction: column;
   overflow: hidden;
   .timeline{
     position: relative;
     order: 2;
     width: 100%;
     display: visible;
     margin-top: 0px;
   }
   .box {
     order: 3;
     width: 100%;
     padding: 0 20px 20px 0;
     margin-top: 60px;
    box-shadow: none;
   }

   .about-us{
     order: 1;
     position: relative;
     top: 0px;
     margin-top: 10px;
     padding: 0px;
     width: 100%;
     box-shadow: none;
   }
 }
    
`;

// export const 