import React,{useState, useEffect} from 'react'
import './model.css';
import * as FaIcons from 'react-icons/fa';



const Model = ({imgSrc}) => {

   const [showModel, setShowModel] = useState(false);

   useEffect(() => {
         setShowModel(true);
   },[imgSrc]);


    return (
        <div className={showModel? "model open" : "model"}>
           <img src={imgSrc} alt="" />
           <FaIcons.FaRegWindowClose onClick={() => setShowModel(false)}/>
        </div>  
    )
}

export default Model;
