import React, {useEffect, useState} from 'react';
import Model from '../Model';
import './initialSlider.css';



const InitialSlider = ({imageData, eventName}) => {
     const [isInitial, setIsInitial] = useState(true);
    //  const [eventname, setEventname] = useState('');
     const [tempimgSrc, setTempImgSrc] = useState("");
     const [showModel, setShowModel] = useState(false);
     const imgUrl = "https://anai-singapore.org/Images/";


     const onClickHandler = (imgSrc) => {
       setTempImgSrc(imgSrc);
       setShowModel(true);
     };


    const checkInitial = ({eventName}) => {
         if(eventName === ''){
        setIsInitial(true);
        // setEventname(eventName);
    } else {
        setIsInitial(false);
        // setEventname('');
    }
     

    }

    useEffect(() => {
        checkInitial({eventName});
    },[eventName])

    return (
      <>
        <div>{showModel && <Model imgSrc={tempimgSrc} />}</div>
           <div className="gallery">
          {imageData.map((item, i) => {
            const { imgfoldername, imgfilename, src } = item;
            let imgsrc = '';
            
            {isInitial ? imgsrc=`${src}` :  imgsrc=`${imgUrl}/${imgfoldername}/${imgfilename}`}
            return (
             <div key={i}>
               <img
                src={imgsrc}
                alt=""
                  className="pics"
                 key={i} onClick={() => onClickHandler(imgsrc)}
                />
              </div>
            );
          })}
        </div>
      </>
    );


}

export default InitialSlider;


