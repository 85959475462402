import React, {useState} from 'react'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
// import * as BsIcons from 'react-icons/bs';
import {MenuData} from './MenuData';
import './SidebarElements.css';
// import {IconContext} from 'react-icons';
import {Link} from 'react-router-dom';


const Sidebar = () => {
  const[sidebar, setSidebar] = useState(false);
  const[navbar, setNavbar] = useState(false);
  const[scrollPos,setScrollPos] = useState(0);

 
  const changeNav = () => {
     setScrollPos(window.scrollY);
     if(scrollPos >= 80){
        setNavbar(true);
     }else{
        setNavbar(false);
     }
  }

  window.addEventListener('scroll', changeNav);
  const toggleSideBar = () => setSidebar(!sidebar)

    return (
        <>
            <div className={navbar ? 'navbar active' : 'navbar'}>
             <Link to="#" className={navbar ? 'menu-bars active':'menu-bars'}>
                <FaIcons.FaBars onClick={toggleSideBar}/>
             </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'} onClick={toggleSideBar}>
             <ul className="nav-menu-items">
              <li className="navbar-toggle">
                <Link to="#" className='menu-bars'>
                 <AiIcons.AiOutlineClose /> 
                </Link>
              </li>
              {MenuData.map((item, index)=> {
                 return (
                     <li key={item.id} className={item.cName}>
                        <Link to={item.path}>
                           {item.icon}
                           <span>{item.title}</span>
                        </Link>
                     </li>
                 );
              })}
             </ul>
            </nav>
        </>

    )
}

export default Sidebar
