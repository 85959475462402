export const initialImgData = [
   
 {   "id": 1,
     "src":"./images/1.jpg",
      "alt":"Gallery",
      "class":"img-1",
 },
 {   "id": 2,
     "src":"./images/2.jpg",
      "alt":"Gallery",
      "class":"img-2"
 },
 {   "id": 3,
     "src":"./images/3.jpg",
      "alt":"Gallery",
      "class":"img-3"
 },
 {   "id": 4,
     "src":"./images/4.jpg",
      "alt":"Gallery",
      "class":"img-4"
 },
 {   "id": 5,
     "src":"./images/5.jpg",
      "alt":"Gallery",
      "class":"img-5"
 },
 {   "id": 6,
     "src":"./images/6.jpg",
      "alt":"Gallery",
      "class":"img-6"
 },
 {   "id": 7,
     "src":"./images/7.jpg",
      "alt":"Gallery",
      "class":"img-7"
 },
 {   "id": 8,
     "src":"./images/8.jpg",
      "alt":"Gallery",
      "class":"img-8"
 },
 {   "id": 9,
     "src":"./images/9.jpg",
      "alt":"Gallery",
      "class":"img-9"
 },
 {   "id": 10,
     "src":"./images/10.jpg",
      "alt":"Gallery",
      "class":"img-10"
 },
 {   "id": 11,
     "src":"./images/11.jpg",
      "alt":"Gallery",
      "class":"img-11"
 },
 {   "id": 12,
     "src":"./images/12.jpg",
      "alt":"Gallery",
      "class":"img-12"
 },
  {   "id": 13,
     "src":"./images/13.jpg",
      "alt":"Gallery",
      "class":"img-13"
 },
 {   "id": 14,
     "src":"./images/14.jpg",
      "alt":"Gallery",
      "class":"img-14"
 },

];