import React from 'react'


const TimelineItem = ({data}) => {
  
    return (
        <div className='timeline-item'>
            <div className="timeline-item-content">
              <span className="tag">
                <h4>{data.title}</h4>
              </span>
              <time>{data.daterange}</time><br />
              <span>{data.text}</span>
              <span className="circle"></span>
            </div>
        </div>
    )
}

export default TimelineItem;
