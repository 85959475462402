import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const GetItemWrapper = styled.div `
   display: flex;
   justify-content: center;
   align-items: start;
   padding: 10px;
   flex-direction: column;
   width: 100%;
     /* border: solid 2px orange; */
`;

export const GetItemHeader = styled.h3 `
   font-size: 20px;
   color: brown;

@media screen and (max-width:480px){
    font-size: 18px;
    padding-left: 10px;
}


@media screen and (max-width:360px){
    font-size: 16px;
}
`;

export const GetItemsDisplay = styled.div `
   display: flex;
   align-items: center;
   width: 100%;
`;

export const GetItemsList = styled.ul `

`;

export const ItemsList = styled(Link) `
    color: brown;
   text-decoration: none;
   list-style: none;
   cursor: pointer;
   li{
         font-size: 18px;
         &:hover{
         font-weight: bold ;
      }
   

@media screen and (max-width:480px){
    font-size: 16px;
    padding-left: 10px;
}


@media screen and (max-width:360px){
    font-size: 12px;
}

`;