export const timelineData = [
  {
    id: 1,
    title: "Start of the Movement",
    daterange: "2002",
    text: "First started at homes and then the sessions at Siva Krishna Temple",
    color: "",
  },
  {
    id: 2,
    title: "First Major Guru Arathana",
    daterange: "2003",
    text: "First milestone - organised and conducted Guru Arathana and inception of ANAI.",
  },
  {
    id: 3,
    title: "Formation of ANAI",
    daterange: "2004",
    text: "Formed the Athma Nyaana Anbu Illam group.",
  },
  {
    id: 4,
    title: "Location struggles",
    daterange: "2006",
    text: "Moved into VEC then moved to Kumon Centre",
  },
  {
    id: 5,
    title: "ANAI officiation",
    daterange: "2010",
    text: "Found our place at 43 Jalan Lengkok, Sembawang. ANAI was officially opened on the 5th of October.",
  },
  {
    id: 6,
    title: "Arrival of our Guru",
    daterange: "2011",
    text: "Sri Guru Raghavendra Swamy statue arrived into ANAI. Registered as a Society",
  },
  {
    id: 7,
    title: "Arrival of Nava Bashana Shiva Lingam",
    daterange: "2018",
    text: "Sponsered by Siddar Chandrakanth. Visit by Pambatti Siddhar",
  },
  {
    id: 8,
    title: "Arrival of Amman",
    daterange: "2018",
    text: "Sponsered by Mr Muralikrishna & Mrs Gunachitra",
  },
   {
    id: 9,
    title: "Arrival of Annjenayar",
    daterange: "2021",
    text: "Sponsered by Mr Vijay & Mrs Shoba",
  },
];
