import Video from '../../videos/clouds.mp4'
//import Img from '../../Images/GuruRaghavendra.png'
import Accordion from '../Accordion';
import PageHeader from '../PageHeader'
//import Footer from '../Footer'

import {
        PageContainer,
        HeroBg,
        VideoBg,
        HeroWrapper,
        Section1, SectH1,

} from './HeroElements';


const Main = () => {
    return (
      <>
       <PageContainer>
         <HeroBg>
             <VideoBg
                autoPlay 
                muted 
                loop
                playsInline 
                src={Video}
                type="video/mp4"
               />
          </HeroBg>

        <HeroWrapper>
          <PageHeader />
             <Section1>
               <SectH1>
                  Om Sat Guru Raghavendraya Namaha
               </SectH1>
              </Section1>
              <Accordion />
           </HeroWrapper>
         </PageContainer>
       </>
    )
}

export default Main;