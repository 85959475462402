import React from 'react';
import './timeline.css';
import {timelineData} from './timelineData';
import TimeLineItem from './TimelineItem';

const TimeLine = () => timelineData.length > 0 && (
        <div className='timeline-container'>
        {
            timelineData.map((data, idx)=> (
                <TimeLineItem data={data} key={idx} />
            ))
        }
      </div>
    )


export default TimeLine;
