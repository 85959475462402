// import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';
import * as BsIcons from 'react-icons/bs';
// import * as GrIcons from 'react-icons/gr';
export const MenuData = [
    {
        id:1,
        title: "Home",
        path:'/',
        icon:<AiIcons.AiFillHome />,
        cName:'nav-text'
    },
    {
        id:2,
        title: "Our Story",
        path:'/OurStory',
        icon:<BsIcons.BsClockHistory />,
        cName:'nav-text'
    },
    {
        id:3,
        title: "Gallery",
        path:'/Gallery',
        icon:<AiIcons.AiOutlineAppstore />,
        cName:'nav-text'
    },
    // {
    //     id:4,
    //     title: "Get in Touch",
    //     path:'/GetInTouch',
    //     icon:<FaIcons.FaAddressCard />,
    //     cName:'nav-text'
    // },
    {
        id:5,
        title: "Key Dates",
        path:'/KeyDates',
        icon:<BsIcons.BsFillCalendar2WeekFill />,
        cName:'nav-text'
     },
    // {
    //     id:6,
    //     title: "Enquiries",
    //     path:'/FAQ',
    //     icon:<BsIcons.BsFillChatLeftTextFill />,
    //     cName:'nav-text'
    // },
];

