import React, {useState} from 'react';
import { BsFillArrowDownCircleFill as FillAD} from "react-icons/bs";


const EachKeyDate = ({id, event, calendar}) => {
    const [showDates, setShowDates] = useState(false);
 
  


       return (
         <>
           <div className="Container">
             <div className="items-container">
               <div className="card-header" key={id}>
                 <b>
                   {event} 
                 </b>
                 <FillAD
                   className={showDates ? "icon open" : "icon"}
                   onClick={() => setShowDates(!showDates)}
                 />
               </div>
               {showDates &&
                 calendar.map((dates, i) => {
                   let index = dates.id;
                   return (
                     <div className="dates-container" key={index}>
                       <p className="dates1">
                         {dates.mthname}, {dates.day}, {dates.weekday}
                       </p>
                       <div className="dates2">
                         <b>Begins:</b> {dates.begin}, <br />
                         <b>Ends:</b> {dates.end}
                       </div>
                     </div>
                   );
                 })}
             </div>
             {showDates && 
               <FillAD
                   className={showDates ? "icon open" : "icon"}
                   onClick={() => setShowDates(!showDates)}
                 />
             }
             
           </div>
         </>
       );    



}

export default EachKeyDate;