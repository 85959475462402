import styled from 'styled-components'
//import LinearGradient from 'react-native-linear-gradient';

export const GalleryHeaderWrapper = styled.div `
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   background: beige;
   width: 80%;
   height: 350px;
   margin: auto;
   margin-top: 40px;
   padding: 0px 0px;
   border-radius: 20px;
   box-shadow: 5px 5px 3px rgba(0,0,0, 0.3);
   z-index: -3;
   /* border: 2px solid grey; */


`;

export const GalleryHeader = styled.div `
   width: 90%;
   margin: auto;
   margin-top: 40px;
   height: auto;
   border-radius: 10px;
   z-index: 3;
   border: dashed 2px blue; 

@media screen and (max-width: 1024px){
   height: 100%;
   /* width: auto; */
   margin-top: -40px;
   margin-bottom:50px;

}

@media screen and (max-width: 480px){
   height: 100%;
   width: 100%;
   margin-top: -40px;

}
`;


export const GalleryHero = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ;
  width: 100%;
  margin: auto;
  height: 300px;
  margin-top: 60px;
  /* border: 2px solid green; */

@media screen and (max-width: 1024px){
   height: 230px;
   margin-top: 20px;
}

@media screen and (max-width: 720px){
   height: 180px;
}

@media screen and (max-width: 640px){
   height: 200px;
}

@media screen and (max-width: 468px){
   height: 150px;
}

`;
export const HeroBg = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    z-index:-1;
    /* margin-top:-60px; */
    /* margin: auto; */
 
`;

export const VideoBg = styled.video `
   width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit:cover;
    background: #232834;
    z-index: -10;

/* @media screen and (max-width: 320px){
   display: none;
} */
`;

export const HeroLabel = styled.h1 `
    position: absolute;
    width: auto;
    height: auto;
    font-size: 4rem;
    color: white;
    letter-spacing: 6px; 
    z-index: 20;
    text-shadow: 2px 2px 10px #fff;

@media screen and (max-width: 1024px){
   margin-top: 10px;
}


@media screen and (max-width: 680px){
   font-size: 2.5rem;
   margin-top: 10px;

}

@media screen and (max-width: 468px){
   font-size: 1.5rem;
   text-shadow: none;
}


@media screen and (max-width: 320px){
   font-size: 1rem;
   text-shadow: none;
}

`;

export const HeroVideoOverlay = styled.div `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 2, 1, 0.4);

@media screen and (max-width: 320px){
    background: rgba(0, 2, 1, 0.6);
}

`;


