import React, {useEffect, useState} from 'react'

import {
  GetItemWrapper,
  GetItemHeader,
  GetItemsDisplay,
  GetItemsList,
  ItemsList,
} from "./GetItemElements";

const Getitems = ({setEventType}) => {
    const [event, setEvent] = useState([]);
    const url = "https://anai-singapore.org/Backend/api/images/read.php"

   
useEffect(() => {
  fetch(url)
    .then((response) => response.json())
    .then((resdata) => {
      setEvent(resdata);
    })
    .catch((error) => {
      console.error(
        "There has been a problem with your fetch operation:",
        JSON.parse(JSON.stringify(error))
      );
    });
}, []);  


    return (
        <GetItemWrapper>
         <GetItemHeader>
           Events        
         </GetItemHeader>
         <GetItemsDisplay>
           <GetItemsList>
            <ItemsList to="#">
             {event.map((item) => {
               return (
                 <li
                   key={item.id}
                   onClick={() =>
                     setEventType({
                       eventId: item.id,
                       eventName: item.event_name,
                     })
                   }
                 >
                   {item.event_name}
                 </li>
               );
             })}
            </ItemsList>
           </GetItemsList>
         </GetItemsDisplay>
       </GetItemWrapper>
    )
}

export default Getitems;
