import React, {useState, useEffect} from 'react';
//import {KeyDatesData} from '../Data/KeyDatesData';
import './styles.css'


const DisplayByMth = ({currMth, currMthNum}) => {
  const [data, setData] = useState([]);
//  const data = KeyDatesData;
 const url = 'https://anai-singapore.org/Backend/api/jsondates/read.php';
 

  const getData= () => {
           fetch(url)
              .then((response) => response.json())
              .then((resdata) => {
                setData(resdata);
              })
              .catch((error) => {
                console.error(
                  "There has been a problem with your fetch operation:",
                  JSON.parse(JSON.stringify(error))
                );
              });
   }

//Logic check for the items that within current mth. 

useEffect(() => {
  getData();
}, []);   

        return (

         <div className="mthlycontainer">
            {data.map((item, idx) => {
              let calendar = item.calendar;
                 
              return (
                <div className="eventsbymth" key={item.event}>
                     <div className="mthlyevent" key={idx} >{item.event}</div>  
                  {calendar.map((mth, idx) => {
                     if (mth.mth === currMthNum) {
                      return (
                      <div className="mthly-items-container" key={idx}>
                        <div key={mth.id} className="mthlyitems">
                          <p>{mth.day}&nbsp;&nbsp;
                          {mth.weekday}</p>
                        </div>
                      </div>   
                    );
                    } //end of if statement
                  })}
                </div>
              );
            })}
         </div>
                   
        );
};

export default DisplayByMth;